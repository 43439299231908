import './App.css';
import React, { useEffect, useState } from 'react';

import Header from './components/Header';
import Producto from './components/Producto';
import Footer from './components/Footer';

import ModalProductos from './components/ModalProductos';
import ModalCarrito from './components/ModalCarrito';
import ModalFinalizar from './components/ModalFinalizar';

import { useCantidad } from './hooks/useCantidad';

import { useCarrito } from './hooks/useCarrito.js';
import { useMonto } from './hooks/useMonto.js';
import { useProdSelect } from './hooks/useProdSelect'
// import ModalFinalizar from './components/ModalFinalizar.js';
import { useModal } from './hooks/useModal';


function App() {

  const formato = new Intl.NumberFormat('de-DE');

  const [carrito, addProduct, deleteProduct, emptyCarrito] = useCarrito([]);
  const [monto, actualizarMonto] = useMonto(0);
  // const [envio, actualizarEnvio] = useMonto(0);
  // const montoEnvio = 500;
  // const minimoCompra = 6000;
  const [isOpenMFinalizar, openModalFinalizar, closeModalFinalizar] = useModal(false); // Modal Info.
  const [prodSelect, selectProd] = useProdSelect(1); 
  const [isOpenM2, openModal2, closeModal2] = useModal(false); // Modal Productos
  const [isOpenMCarrito, OpenModalCarrito, closeModalCarrito] = useModal(false); // Modal Carrito.

  const [productos, setProductos] = useState([])

  const [suc, setSuc] = useState('');

  const actualUrl = window.location.hostname.split(".").length == 4 ? window.location.hostname.split(".")[1] : window.location.hostname.split(".")[0]  ;

  useEffect(()=>{
    if(actualUrl === 'sushisakegarin'){
      setSuc('Garin')
    } else if(actualUrl === 'sushisakesanisidro'){
      setSuc('San Isidro')
  } else if(actualUrl === 'sushisakepilar'){
      setSuc('Pilar')
    }else if(actualUrl === 'sushisakeescobar'){
      setSuc('Escobar')
    }else {
      setSuc('Test Local')
    }
    //alert(actualUrl)
  },[actualUrl])
  

  //actualizarMonto(1000)

  function OpenReset(idProducto) {
    //resetCant();
    openModal2();
    selectProd(idProducto);
  }

  function CloseReset() {
    //resetCant();
    closeModal2();
  }

  var goOption = (go)=>{
    document.getElementById( go ).scrollIntoView(true);

    console.log(document.getElementById( go ))

    var links = document.querySelectorAll( '.desplazar' ); // agregar clase dps

    links.forEach( link =>{
        link.addEventListener( "click",event => {
            
            event.preventDefault();
            // let moveTo = link.getAttribute( 'moveTo' );
            // document.getElementById( moveTo ).scrollIntoView(true);

        });
    });
  }

  var categorias = [
    {nombre:'Promociones'},
    {nombre:'Combos'},
    {nombre:'Ensaladas & Woks'},
    {nombre:'Premium'},
    {nombre:'Salmón'},
    {nombre:'Tamagos'},
    {nombre:'Variados'},
    {nombre:'Veggies'},
    {nombre:'Calentitos'},
    {nombre:'Especiales'},
    // {nombre:'Bebidas sin Adilcohol'},
  ]


  if(suc == "Pilar"){
    categorias = [
      {nombre:'Promociones'},
      {nombre:'Combos'},
      // {nombre:'Ensaladas & Woks'},
      {nombre:'Premium'},
      {nombre:'Salmón'},
      {nombre:'Tamagos'},
      {nombre:'Variados'},
      {nombre:'Veggies'},
      {nombre:'Calentitos'},
      {nombre:'Especiales'},
      {nombre:'Bebidas sin Alcohol'},
    ]
  }

useEffect(() => {
  var productosX = [
    {id:1, uid:1, nombre:'32 Variados', stock: true, cantidad:'32 piezas',detalle:'4 Niguiris de Salmón; 8 Makis Salmón; 6 Philadelphia; 6 California; 4 Sweet Grill; 4 Salmon Grill', 
      precio:17000, promo:true, precioPromo:14400,categoria:'Promociones', codigo:'', src:'./img/32-Variados-300x300.jpg'},
    {id:2, uid:2, nombre:'Tamago Mix 12', stock: true, cantidad:'12 piezas',detalle:'4 Tamago Sake; 4 Tamago Roll; 4 Tamago Rice', 
      precio:8700, precioPromo:'',categoria:'Combos', codigo:'', src:'./img/tamago-mix-12-300x300.jpg'},
    {id:3, uid:3, nombre:'30 Mix', stock: true, cantidad:'30 piezas',detalle:'2 Niguiris de Salmón; 4 Salmon Grill; 4 California; 4 Philadelphia; 4 Makis Salmon; 12 Hot', 
      precio:16000, promo:true, precioPromo:11200,categoria:'Combos', codigo:'', src:'./img/30-Mix-300x300.jpg'},
    {id:4, uid:4, nombre:'Sake Mix x24', stock: true, cantidad:'24 piezas',detalle:'4 New York Phila; 4 Philadelphia; 4 Salmon Grill; 4 Sweet Grill; 4 Pasión; 4 Ebi Dai', 
      precio:15000, promo:true, precioPromo:11250,categoria:'Combos', codigo:'', src:'./img/Sake-Mix-24-300x300.jpg'},
    {id:5, uid:5, nombre:'Combos Veggie', stock: true, cantidad:'x14 x18 x32 x70 piezas', 
      piezas: [{x:"x14", precio:7000}, {x:"x18", precio:7500}, {x:"x32", precio:9500}, {x:"x70", precio:17000}] ,detalle:'x14: 2 Niguiris Advocado; 3 Capre; 3 Selva; 3 Sake; 3 Cherry  //x18: 2 Niguiris Advocado; 4 Capre; 4 Selva; 4 Sake; 4 Cherry //x32: 4 Niguiris Advocado; 4 Maki Vegge; 6 Capre; 6 Selva; 6 Sake; 6 Cherry //x70: 10 Niguiris Advocado; 12 Maki Vegge; 12 Capre; 12 Selva; 12 Sake; 12 Cherry', precio:'$7.000 – $17.000', precioPromo:'',categoria:'Combos', codigo:'', src:'./img/combos-veggie-300x300.jpg'},
    {id:6, uid:6, nombre:'Combos Premium', stock: true, cantidad:'x14 x18 x32 x70 piezas', 
      piezas: [{x:"x14", precio:9100}, {x:"x18", precio:9800}, {x:"x32", precio:18000}, {x:"x70", precio:27000}] ,detalle:'x14: 2 Niguiris de Salmón; 4 Bs As; 4 Ebi; 4 Philadelphia // x18: 2 Niguiris de Salmón; 4 Maki Ebi; 4 Ebi; 4 Philadelphia; 4 Bs As // x32: 4 Ebi Dai; 4 Niguiris de Salmón; 4 Maki Ebi; 6 Ebi; 6 Philadelphia; 4 Miami; 4 Pasión // x70: 6 Ebi Dai; 7 Niguiris de Salmón; 3 Tamago Rice; 6 Makis Ebi; 12 Philadelphia; 12 Ebi; 6', precio:'$9.100 – $27.000', precioPromo:'',categoria:'Combos', codigo:'', src:'./img/Combo-Premium-32-300x300.jpg'},
    {id:7, uid:7, nombre:'Combos Salmón', stock: true, cantidad:'x14 x18 x32 x70 piezas', 
      piezas: [{x:"x14", precio:10000}, {x:"x18", precio:11200}, {x:"x32", precio:19000}, {x:"x70", precio:28000}] ,detalle:'x14: 2 Niguiris de Salmón; 4 Philadelphia; 4 New York Phila; 4 New York // x18: 4 Philadelphia; 4 New York Phila; 4 New York; 4 Makis Philadelphia // x32: 4 Niguiris de Salmón; 4 Sashimis de Salmón; 8 Makis Philadelphia; 4 New York: 6 Philadelphia; 6 New York Phila // x70: 7 Sashimi; 7 Niguiris de Salmón; 12 Makis Phila; 12 Philadelphia; 12 New York', precio:'$10.000 – $28.000', precioPromo:'',categoria:'Combos', codigo:'', src:'./img/Combo-Salmon-300x300.jpg'},
    {id:8, uid:8, nombre:'Combos Variados', stock: true, cantidad:'x14 x18 x32 x70 piezas', 
      piezas: [{x:"x14", precio:8900}, {x:"x18", precio:9300, promo:true, precioPromo:7800}, {x:"x32", precio:17000, promo:true, precioPromo:14400}, {x:"x70", precio:25000}] ,detalle:'x14: 2 Niguiris de Salmón; 4 Sweet Grill; 4 California; 4 Philadelphia // x18 : 2 Niguiris de Salmón; 4 Salmon Grill; 4 California; 4 Philadelphia; 4 Makis Salmón // x32:  4 Niguiris de Salmón; 8 Makis Salmón; 6 Philadelphia; 6 California; 4 Sweet Grill; 4 Salmon Grill // x70:  8 Tamago Rice; 6 Niguiris de Salmón; 8 Makis Salmón; 12 Philadelphia; 6 Tuna; 6 Salmon Grill; 12 California; 12 Grill Fried', precio:'$8.900 – $25.000', precioPromo:'',categoria:'Combos', codigo:'', src:'./img/18-Variados-300x300.jpg'},
    {id:9, uid:9, nombre:'Lango Salad', stock: true, cantidad:'1 unidad',detalle:'Langostinos, Philadelphia, Palta y Arroz', 
      precio:4800, precioPromo:'',categoria:'Ensaladas & Woks', codigo:'', src:'./img/langos-salad-300x300.jpg'},
    {id:10, uid:10, nombre:'Sushi Salad', stock: true, cantidad:'1 unidad',detalle:'Palta, Arroz, Philadelphia y Salmón', 
      precio:5000, precioPromo:'',categoria:'Ensaladas & Woks', codigo:'', src:'./img/sushi-salad-300x300.jpg'},
    {id:11, uid:11, nombre:'Ebi', stock: true, cantidad:'x6 x12 piezas', 
      piezas: [{x:"x6", precio:3900}, {x:"x12", precio:7800}] ,detalle:'Langostinos, Palta y Philadephia', precio:'$3.900 – $7.800', precioPromo:'',categoria:'Premium', codigo:'', src:'./img/Ebi-300x300.jpg'},
    {id:12, uid:12, nombre:'Ebi Philadelphia', stock: true, cantidad:'x6 x12 piezas', 
      piezas: [{x:"x6", precio:3900}, {x:"x12", precio:7800}] ,detalle:'Langostinos, Ciboulette, y Philadelphia', precio:'$3.900 – $7.800', precioPromo:'',categoria:'Premium', codigo:'', src:'./img/Ebi-Philadelphia-300x300.jpg'},
    {id:13, uid:13, nombre:'Bs As', stock: true, cantidad:'x6 x12 piezas', 
      piezas: [{x:"x6", precio:3900}, {x:"x12", precio:7800}] ,detalle:'Langostinos y Philadelphia envuelto en Salmón', precio:'$3.900 – $7.800', precioPromo:'',categoria:'Premium', codigo:'', src:'./img/Buenos-Aires-300x300.jpg'},
    {id:14, uid:14, nombre:'Ebi Dai', stock: true, cantidad:'x6 x12 piezas', 
      piezas: [{x:"x6", precio:3900}, {x:"x12", precio:7800}] ,detalle:'Langostinos panisados, Palta y Philadelphia', precio:'$3.900 – $7.800', precioPromo:'',categoria:'Premium', codigo:'', src:'./img/Ebi-Dai-300x300.jpg'},
    {id:15, uid:15, nombre:'Pasión', stock: true, cantidad:'x6 x12 piezas', 
      piezas: [{x:"x6", precio:3900}, {x:"x12", precio:7800}] ,detalle:'Langostinos y Philadelphia envueltos en Salmón coronado con batatas fritas', precio:'$3.900 – $7.800', precioPromo:'',categoria:'Premium', codigo:'', src:'./img/Diseno-sin-titulo-6-300x300.jpg'},
    {id:16, uid:16, nombre:'Niguiri Salmón', stock: true, cantidad:'x3 x6 piezas',
    piezas: [{x:"x3", precio:2000}, {x:"x6", precio:4000}] ,detalle:'', precio:'$2.000 – $4.000', precioPromo:'',categoria:'Salmón', codigo:'', src:'./img/Niguiri-x-3-300x300.jpg'},
    {id:17, uid:17, nombre:'Philadelphia', stock: true, cantidad:'x6 x12 piezas', 
      piezas: [{x:"x6", precio:3900}, {x:"x12", precio:7800}] ,detalle:'Salmón, Ciboulette y Philadelphia', precio:'$3.900 – $7.800', precioPromo:'',categoria:'Salmón', codigo:'', src:'./img/Philadelphia-2-300x300.jpg'},
    {id:18, uid:18, nombre:'Nueva York', stock: true, cantidad:'x6 x12 piezas', 
      piezas: [{x:"x6", precio:3900}, {x:"x12", precio:7800}] ,detalle:'Salmón y Palta', precio:'$3.900 – $7.800', precioPromo:'',categoria:'Salmón', codigo:'', src:'./img/New-York-300x300.jpg'},
    {id:19, uid:19, nombre:'Nueva York Phila', stock: true, cantidad:'x6 x12 piezas', 
      piezas: [{x:"x6", precio:4200}, {x:"x12", precio:8400}] ,detalle:'Salmón, Palta y Philadelphia', precio:'$4.200 – $8.400', precioPromo:'',categoria:'Salmón', codigo:'', src:'./img/New-York-Phila-300x300.jpg'},
    {id:20, uid:20, nombre:'Miami', stock: true, cantidad:'x6 x12 piezas', piezas: 
      [{x:"x6", precio:4200}, {x:"x12", precio:8400}] ,detalle:'Salmón tempura, Philadelphia y Palta', precio:'$4.200 – $8.400', precioPromo:'',categoria:'Salmón', codigo:'', src:'./img/Miami-300x300.jpg'},
    {id:21, uid:21, nombre:'Tamago Roll', stock: true, cantidad:'x6 x12 piezas', 
      piezas: [{x:"x6", precio:3400}, {x:"x12", precio:6800}] ,detalle:'Salmón y Philadelphia, con Ciboulette envuelto en tamago', precio:'$3.400 – $6.800', precioPromo:'',categoria:'Tamagos', codigo:'', src:'./img/tamago-roll-300x300.jpg'},
    {id:22, uid:22, nombre:'Tamago Rice', stock: true, cantidad:'x6 x12 piezas', 
      piezas: [{x:"x6", precio:3400}, {x:"x12", precio:6800}] ,detalle:'Salmón y Philadelphia, con Ciboulette envuelto en tamago', precio:'$3.400 – $6.800', precioPromo:'',categoria:'Tamagos', codigo:'', src:'./img/tamago-rice-300x300.jpg'},
    {id:23, uid:23, nombre:'Tamago Sake', stock: true, cantidad:'x6 x12 piezas', 
      piezas: [{x:"x6", precio:3400}, {x:"x12", precio:6800}] ,detalle:'Salmón, Philadelphia, Mango envuelto en tamago con Maracuyá', precio:'$3.400 – $6.800', precioPromo:'',categoria:'Tamagos', codigo:'', src:'./img/tamago-sake-300x300.jpg'},
    {id:24, uid:24, nombre:'Tamago Pasión', stock: true, cantidad:'x6 x12 piezas', 
      piezas: [{x:"x6", precio:3400}, {x:"x12", precio:6800}] ,detalle:'Salmón, Philadelphia, Mago envuelto en tamago y Salmón con Maracuyá', precio:'$3.400 – $6.800', precioPromo:'',categoria:'Tamagos', codigo:'', src:'./img/tamago-pasion-300x300.jpg'},
    {id:25, uid:25, nombre:'Tamago Mix 12', stock: true, cantidad:'12 piezas',detalle:'4 Tamago Sake; 4 Tamago Roll; 4 Tamago Rice', 
      precio:8700, precioPromo:'',categoria:'Tamagos', codigo:'', src:'./img/tamago-mix-12-300x300.jpg'},
    {id:26, uid:26, nombre:'Salmon Grill', stock: true, cantidad:'x6 x12 piezas', 
    piezas: [{x:"x6", precio:3900}, {x:"x12", precio:7800}] ,detalle:'Salmón grillado, Ciboulette y Philadelphia', precio:'$3.900 – $7.800', precioPromo:'',categoria:'Variados', codigo:'', src:'./img/Salmon-Grill-300x300.jpg'},
    {id:27, uid:27, nombre:'California', stock: true, cantidad:'x6 x12 piezas', 
    piezas: [{x:"x6", precio:3600}, {x:"x12", precio:7200}] ,detalle:'Kanikama y Palta', precio:'$3.600 – $7.200', precioPromo:'',categoria:'Variados', codigo:'', src:'./img/California-300x300.jpg'},
    {id:28, uid:28, nombre:'Tuna', stock: true, cantidad:'x6 x12 piezas', 
      piezas: [{x:"x6", precio:3600}, {x:"x12", precio:7200}] ,detalle:'Atún, Ciboulette y Philadelphia', precio:'$3.600 – $7.200', precioPromo:'',categoria:'Variados', codigo:'', src:'./img/Tuna-300x300.jpg'},
    {id:29, uid:29, nombre:'Sweet Grill', stock: true, cantidad:'x6 x12 piezas', 
      piezas: [{x:"x6", precio:3900}, {x:"x12", precio:7800}] ,detalle:'Salmón grillado agridulce, Ciboulette y Philadelphia', precio:'$3.900 – $7.800', precioPromo:'',categoria:'Variados', codigo:'', src:'./img/Sweet-Grill-300x300.jpg'},
    {id:30, uid:30, nombre:'Selva', stock: true, cantidad:'x6 x12 piezas', 
      piezas: [{x:"x6", precio:3200}, {x:"x12", precio:6400}] ,detalle:'Zucchini, Zanahoria, Philadelphia, con lluvia de Ciboulette', precio:'$3.200 – $6.400', precioPromo:'',categoria:'Veggies', codigo:'', src:'./img/selva-300x300.jpg'},
    {id:31, uid:31, nombre:'Capre', stock: true, cantidad:'x6 x12 piezas', 
      piezas: [{x:"x6", precio:3200}, {x:"x12", precio:6400}] ,detalle:'Cherry, Albahaca y Philadelphia, con lluvia de Sésamo', precio:'$3.200 – $6.400', precioPromo:'',categoria:'Veggies', codigo:'', src:'./img/capre-300x300.jpg'},
    {id:32, uid:32, nombre:'Sake', stock: true, cantidad:'x6 x12 piezas', 
      piezas: [{x:"x6", precio:3200}, {x:"x12", precio:6400}] ,detalle:'Palta, Zanahoria y Philadelphia, coronados con Sésamo y Batatas fritas', precio:'$3.200 – $6.400', precioPromo:'',categoria:'Veggies', codigo:'', src:'./img/sake-300x300.jpg'},
    {id:33, uid:33, nombre:'Cherry', stock: true, cantidad:'x6 x12 piezas', 
      piezas: [{x:"x6", precio:3200}, {x:"x12", precio:6400}] ,detalle:'Cherry, Rúcula y Philadelphia con lluvia de Batatas fritas', precio:'$3.200 – $6.400', precioPromo:'',categoria:'Veggies', codigo:'', src:'./img/cherry-300x300.jpg'},
    {id:34, uid:34, nombre:'Niguiri Avocado', stock: true, cantidad:'x3 x6 piezas', 
      piezas: [{x:"x3", precio:1500}, {x:"x6", precio:3000}] ,detalle:'Arroz cubierto con lámina de Palta, anudada con Alga Nori', precio:'$1.500 – $3.000', precioPromo:'',categoria:'Veggies', codigo:'', src:'./img/niguiri-advocado-300x300.jpg'},
    {id:35, uid:35, nombre:'Grilled Fried', stock: true, cantidad:'x6 x12 piezas', 
      piezas: [{x:"x6", precio:3900}, {x:"x12", precio:7800}] ,detalle:'Salmón cocido y Philadelphia', precio:'$3.900 – $7.800', precioPromo:'',categoria:'Calentitos', codigo:'', src:'./img/Grilled Fried-300x300.jpg'},
    {id:36, uid:36, nombre:'Hot', stock: true, cantidad:'x6 x12 piezas', 
      piezas: [{x:"x6", precio:3900}, {x:"x12", precio:7800}] ,detalle:'Salmón cocido, Ciboulette y Philadelphia', precio:'$3.900 – $7.800', precioPromo:'',categoria:'Calentitos', codigo:'', src:'./img/Hot-300x300.jpg'},
    {id:37, uid:37, nombre:'Ebi Fried', stock: true, cantidad:'x6 x12 piezas', 
      piezas: [{x:"x6", precio:3900}, {x:"x12", precio:7800}] ,detalle:'Langostinos y Philadelphia', precio:'$3.900 – $7.800', precioPromo:'',categoria:'Calentitos', codigo:'', src:'./img/Ebi Fried-300x300.jpg'},
    {id:38, uid:38, nombre:'Hot Teri', stock: true, cantidad:'x6 x12 piezas', 
      piezas: [{x:"x6", precio:3900}, {x:"x12", precio:7800}] ,detalle:'Salmón, Langostinos, y Philadelphia en tempura', precio:'$3.900 – $7.800', precioPromo:'',categoria:'Calentitos', codigo:'', src:'./img/Hot-Teri-300x300.jpg'},
    {id:39, uid:39, nombre:'Hot Teri Sake', stock: true, cantidad:'x6 x12 piezas', 
      piezas: [{x:"x6", precio:3900}, {x:"x12", precio:7800}] ,detalle:'Salmón, Langostinos, Philadelphia y Mostaza en tempura', precio:'$3.900 – $7.800', precioPromo:'',categoria:'Calentitos', codigo:'', src:'./img/Hot Teri Sake-300x300.jpg'},
    {id:40, uid:40, nombre:'Empanadas Chinas x3', stock: true, cantidad:'3 unidades',detalle:'', 
      precio:2500, precioPromo:'',categoria:'Especiales', codigo:'', src:'./img/empanaditas-300x300.jpg'},
    {id:41, uid:41, nombre:'Rabas x10', stock: true, cantidad:'10 unidades',detalle:'', 
      precio:3466.67, precioPromo:'',categoria:'Especiales', codigo:'', src:'./img/rabas-1-300x300.jpg'},
    {id:42, uid:42, nombre:'30 Mix', stock: true, cantidad:'30 piezas',detalle:'2 Niguiris de Salmón; 4 Salmon Grill; 4 California; 4 Philadelphia; 4 Makis Salmon; 12 Hot', 
      precio:16000, promo:true, precioPromo:11200,categoria:'Promociones', codigo:'', src:'./img/30-Mix-300x300.jpg'},  
    {id:43, uid:43, nombre:'18 Variados', stock: true, cantidad:'18 piezas',detalle:'2 Niguiris de Salmón; 4 Salmon Grill; 4 California; 4 Philadelphia; 4 Makis Salmón', 
      precio:9300, promo:true, precioPromo:5578,categoria:'Promociones', codigo:'', src:'./img/32-Variados-300x300.jpg'},  
    {id:44, uid:44, nombre:'Combo 50', stock: true, cantidad:'50 piezas',detalle:'32 Piezas frias variadas y 18 Piezas calientes', 
      precio:22000, promo:true, precioPromo:15400,categoria:'Combos', codigo:'', src:'./img/30-Mix-300x300.jpg'},  
    {id:45, uid:45, nombre:'Wok Lomo', stock: true, cantidad:'1 unidad',detalle:'Arroz, Verduras y Carne', 
      precio:4600, precioPromo:'',categoria:'Ensaladas & Woks', codigo:'', src:'./img/wok_carne.png'},
    {id:46, uid:46, nombre:'Wok Pollo', stock: true, cantidad:'1 unidad',detalle:'Arroz, Verduras y Pollo', 
      precio:4400, precioPromo:'',categoria:'Ensaladas & Woks', codigo:'', src:'./img/wok_pollo.png'},
    {id:47, uid:47, nombre:'Wok Langostinos', stock: true, cantidad:'1 unidad',detalle:'Arroz, Verduras y Langostino', 
      precio:4933.33, precioPromo:'',categoria:'Ensaladas & Woks', codigo:'', src:'./img/wok_lango.png'},
    {id:48, uid:48, nombre:'Wok Salmon', stock: true, cantidad:'1 unidad',detalle:'Arroz, Verduras y Salmon', 
      precio:4933.33, precioPromo:'',categoria:'Ensaladas & Woks', codigo:'', src:'./img/wok_salmon.png'},
    {id:49, uid:49, nombre:'Langostinos Fried x5', stock: true, cantidad:'5 unidades',detalle:'', 
      precio:3666.67, precioPromo:'',categoria:'Especiales', codigo:'', src:'./img/LangostinoFrito.jpg'},
    {id:50, uid:50, nombre:'Sake Mix x24', stock: true, cantidad:'24 piezas',detalle:'4 New York Phila; 4 Philadelphia; 4 Salmon Grill; 4 Sweet Grill; 4 Pasión; 4 Ebi Dai', 
      precio:15000, promo:true, precioPromo:11250,categoria:'Promociones', codigo:'', src:'./img/Sake-Mix-24-300x300.jpg'},
    {id:51, uid:51, nombre:'Combo 50', stock: true, cantidad:'50 piezas',detalle:'32 Piezas frias variadas y 18 Piezas calientes', 
      precio:22000, promo:true, precioPromo:15400,categoria:'Promociones', codigo:'', src:'./img/30-Mix-300x300.jpg'},  
]

if(suc == "Pilar"){

  productosX.push({id:52, uid:52, nombre:'Benedictino', stock: true, cantidad:'Agua sin Gas - 500ml',detalle:'Agua sin Gas - 500ml', 
  precio:1157.5, precioPromo:'',categoria:'Bebidas sin Alcohol', codigo:'', src:'./img/Benedictinosingas.jpg'});
  productosX.push({id:53, uid:53, nombre:'Benedictino', stock: true, cantidad:'Agua con Gas - 500ml',detalle:'Agua con Gas - 500ml', 
  precio:1157.5, precioPromo:'',categoria:'Bebidas sin Alcohol', codigo:'', src:'./img/Benedictinocongas.jpg'});
  productosX.push({id:54, uid:53, nombre:'Aquarius', stock: true, cantidad:'Manzana - 500ml',detalle:'Manzana - 500ml', 
  precio:1157.5, precioPromo:'',categoria:'Bebidas sin Alcohol', codigo:'', src:'./img/Aqmanzana.jpg'});
  productosX.push({id:55, uid:55, nombre:'Aquarius', stock: true, cantidad:'Pera - 500ml',detalle:'Pera - 500ml', 
  precio:1157.5, precioPromo:'',categoria:'Bebidas sin Alcohol', codigo:'', src:'./img/Aqpera.jpg'});
  productosX.push({id:56, uid:56, nombre:'Aquarius', stock: true, cantidad:'Romelo - 500ml',detalle:'Romelo - 500ml', 
  precio:1157.5, precioPromo:'',categoria:'Bebidas sin Alcohol', codigo:'', src:'./img/Aqpomelo.jpg'});
  productosX.push({id:57, uid:57, nombre:'Aquarius', stock: true, cantidad:'Romelo Rosado - 500ml',detalle:'Romelo Rosado - 500ml', 
  precio:1157.5, precioPromo:'',categoria:'Bebidas sin Alcohol', codigo:'', src:'./img/Aqpomelorosa.jpg'});
  productosX.push({id:58, uid:58, nombre:'Aquarius', stock: true, cantidad:'Limonada - 500ml',detalle:'Limonada - 500ml', 
  precio:1157.5, precioPromo:'',categoria:'Bebidas sin Alcohol', codigo:'', src:'./img/Aqlimonada.jpg'});
  productosX.push({id:59, uid:59, nombre:'Coca-Cola', stock: true, cantidad:'Original - Lata 354ml',detalle:'Original - Lata 354ml', 
  precio:925.7, precioPromo:'',categoria:'Bebidas sin Alcohol', codigo:'', src:'./img/Cocaoriginallata.jpg'});
}


  productosX.forEach((p, index) => {
    

    if(suc == "Pilar"){

      productosX[index].precio = Math.round((p.precio * 1.2 * 1.25 * 1.20 * 1.20 * 1.15* 1.15)/100)*100 ; // 15% Solo en Bar
      productosX[index].precioPromo = Math.round((p.precioPromo * 1.2 * 1.25 * 1.20 * 1.20 * 1.15* 1.15)/100)*100 ; // 15% Solo en Bar

    if (productosX[index].piezas){
      productosX[index].piezas.forEach((pz, indexpz) => {
        productosX[index].piezas[indexpz].precio = Math.round((pz.precio * 1.2 * 1.25 * 1.20 * 1.20 * 1.15* 1.15)/100)*100 ; // 15% Solo en Bar
        productosX[index].piezas[indexpz].precioPromo = Math.round((pz.precioPromo * 1.2 * 1.25 * 1.20 * 1.20 * 1.15* 1.15)/100)*100 ; // 15% Solo en Bar
      });
    }

    }else{

      productosX[index].precio = Math.round(p.precio * 1.2 * 1.25 * 1.20 * 1.20* 1.15) ; 
      productosX[index].precioPromo = Math.round(p.precioPromo * 1.2 * 1.25 * 1.20 * 1.20 * 1.15); 

    if (productosX[index].piezas){
      productosX[index].piezas.forEach((pz, indexpz) => {
        productosX[index].piezas[indexpz].precio = Math.round(pz.precio * 1.2 * 1.25 * 1.20 * 1.20 * 1.15) ; 
        productosX[index].piezas[indexpz].precioPromo = Math.round(pz.precioPromo * 1.2 * 1.25 * 1.20 * 1.20 * 1.15) ; 
      });
    }

    }


    

    if (productosX[index].piezas){

      productosX[index].piezas.forEach((pz, indexpz) => {
        console.log(productosX[index].nombre, productosX[index].piezas[indexpz].x,   productosX[index].piezas[indexpz].precio, productosX[index].piezas[indexpz].precioPromo)
      });
    }

  });
  setProductos(productosX);
},[suc]); // Probar


useEffect(() => {
  let subtotal =0;
  carrito.forEach( (p) => {
    subtotal = subtotal + (p.precio * p.cantidad)
  });
  actualizarMonto(subtotal);
  // if(subtotal > minimoCompra){
  //   actualizarEnvio(0);
  // }else{
  //   actualizarEnvio(montoEnvio);
  // }
   // eslint-disable-next-line react-hooks/exhaustive-deps
},[carrito]);

  return (
    <div className="App">
      <ModalProductos isOpen={isOpenM2} closeModal={CloseReset} data={productos[prodSelect]} addProduct={addProduct} openModalFinalizar = {openModalFinalizar}/>
      {isOpenMCarrito &&
      <ModalCarrito carrito = {carrito} deleteProduct = {deleteProduct} monto = {monto} openModalFinalizar = {openModalFinalizar} closeModalCarrito={closeModalCarrito}/>
      }
      <ModalFinalizar isOpen={isOpenMFinalizar} closeModal ={closeModalFinalizar} carrito= {carrito} monto= {monto} emptyCarrito={emptyCarrito}/>
      <Header monto={monto} OpenModalCarrito = {OpenModalCarrito}/>
      <div className="containers">
        <div Style ={"margin-top: 15px; justify-content: center;  display: flex;"}>
          <ul Style={"display: flex; padding-bottom: 10px; overflow-x: scroll; padding-left: 0px; align-items: center; font-family: Nunito,serif;"}>

            { categorias.map((cate) => {
                return  <>
                <li className='desplazar' onClick={()=>goOption(cate.nombre)}>{cate.nombre}</li>
                </>
            })
            }
          </ul>
        </div>
        { categorias.map((cate) => {
                return  <>
                  <div id={cate.nombre}>
                    <div Style="    font-family: Nunito,serif;    font-size: 1.4em;     color: #101010;    text-align: center;    margin: .5em;">
                        {cate.nombre}
                    </div>

                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" Style={"padding: 15px;  display: inline-flex; flex-wrap: wrap;font-family: Roboto,serif; font-size: 1em; color: #010101;"}>
                    {
                      productos.map((prod) => {
                        if(prod.categoria === cate.nombre && prod.stock === true){
                          return  <>
                          <Producto data={prod} openModal={OpenReset}></Producto>
                          </>
                        }else{
                          return  <></>
                        }
                      })
                    }
                    </div>
                  </div>
                </>
            })
        }
        <div Style={"height:70px"}>

        </div>
      </div>
        { monto > 0 &&  
          <Footer monto={monto} OpenModalCarrito={OpenModalCarrito}></Footer>
        }
      {/* <ModalFinalizar isOpen={isOpenMFinalizar} closeModal={closeModalFinalizar} carrito={carrito} monto={monto} envio={envio} emptyCarrito={emptyCarrito}></ModalFinalizar> */}
    </div>
  );
}

export default App;
