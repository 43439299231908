import "./Modal.css";
// import div from 'react-bootstrap/div';
// import Col from 'react-bootstrap/Col';
import { useNav } from '../hooks/useNav';
import { useEffect, useState } from "react";
// import Button from 'react-bootstrap/Button';
// import Form from 'react-bootstrap/Form';
import { useForm } from '../hooks/useForm';

// import { Loader } from "@googlemaps/js-api-loader";

// import Nav from 'react-bootstrap/Nav';
const initialForm = {
    nombre:"",
    direccion: "",
    pisoDepto: "",
    entreCalle: "",
    localidad: "",
    codigoDesc:''
}


const Modal = ({isOpen, closeModal, carrito, monto, emptyCarrito}) => {

    const {form, handleChange, handleBlur, handleSubmit, setForm} = useForm(initialForm)
    // const [telefono, setTelefono] = useState('5491125948493'); // Escobar
    // const [telefono, setTelefono] = useState('5491164117034'); // Garin
    const [telefono, setTelefono] = useState(''); // San Isidro

    //  const [suc, setSuc] = useState('Escobar');
    // const [suc, setSuc] = useState('Garin');
    const [suc, setSuc] = useState('');

        //  const [direccion, setDireccion] = useState('Av. 25 de Mayo 556, Escobar');
        // const [direccion, setDireccion] = useState('Mateo Churich 372, Garin');
    const [direccion, setDireccion] = useState('');

    const [descuento, setDescuento] = useState(0);

    const actualUrl = window.location.hostname.split(".").length == 4 ? window.location.hostname.split(".")[1] : window.location.hostname.split(".")[0]  ;
    
    useEffect(()=>{

        setDescuento(0)
        setForm(initialForm)

        },[isOpen])

    useEffect(()=>{
        if(actualUrl === 'sushisakegarin'){
            setSuc('Garin')
            setTelefono('5491164117034')
            setDireccion('Mateo Churich 372, Garin')
        } else if(actualUrl === 'sushisakesanisidro'){
            setSuc('San Isidro')
            setTelefono('5491128477011')
            setDireccion('Blanco de Escalada 62, San Isidro')
        } else if(actualUrl === 'sushisakepilar'){
            setSuc('Pilar')
            setTelefono('5491126995894')
            setDireccion('Av. Caamaño 662, Villa Rosa, Pilar')
        } else if(actualUrl === 'sushisakeescobar') {
            setSuc('Escobar')
            setTelefono('5491125948493')
            setDireccion('Av. 25 de Mayo 556, Escobar')
        }else {
            setSuc('Test Local')
            setTelefono('5491125948493')
            setDireccion('Av. 25 de Mayo 556, Escobarx')
        }
        //alert(actualUrl)
        },[actualUrl])    
    
    const handleModalContainerClick = e => e.stopPropagation();
    const formato = new Intl.NumberFormat('de-DE');
    const [nav, cambioNav] = useNav(1); 

    const [modoEnvio, setModoEnvio] = useState('Delivery');

    const [text, setText] = useState("");
    const newText  = (msj) => setText(msj);

    function aplicarDescuento(){
        setDescuento(0)
        if (form.codigoDesc.length > 0){
            if (form.codigoDesc === 'CANDE10' ){
            // if (1 == 2 ){
                let desc =0;
                carrito.forEach((prod) => {
                    desc = desc + (prod.promo == true? 0 : (prod.cantidad * prod.precio) * 0.10 );
                });
                setDescuento(desc)
                alert("Codigo Correcto, Descuento "+formato.format(desc) + ". \nRecordar que el descuento NO aplica en Productos con Promo.")
            }
            // else if (form.codigoDesc === 'WOK' ){
            //     // if (1 == 2 ){
            //         let desc =0;
            //         carrito.forEach((prod) => {
            //             if(prod.uid == 45 || prod.uid == 46 || prod.uid == 47 || prod.uid == 48){
            //                 desc = desc + (prod.promo == true? 0 : (prod.cantidad * prod.precio) * 0.15 );
            //             }
            //         });
            //         setDescuento(desc)
            //         alert("Codigo Correcto, Descuento "+formato.format(desc) + ". \nRecordar que el descuento SOLO aplica en Woks.")
            // }
            // else if (form.codigoDesc === 'SEMANASUSHI' ){
            //     // if (1 == 2 ){
            //         let desc =0;
            //         carrito.forEach((prod) => {
            //             desc = desc + ((prod.cantidad * prod.precio) * 0.15 );
            //         });
            //         setDescuento(desc)
            //         alert("Codigo Correcto, Descuento "+formato.format(desc) + ". \nRecordar que el descuento aplica en Productos y Promos.")
            // }
            else{
                alert("Codigo Incorrecto ó El mismo ya no esta vigente.")
            }
        }
    }

    function generarURL() {
        let texto;

        texto = "Hola Sushi Sake "+suc+" !!\nSoy *"+form.nombre+"* y quiero hacer el siguiente pedido:\n\n";
        
        carrito.forEach(prod => {
            texto = texto + "■ " + prod.cantidad +" x " +prod.nombre +" ";
            prod.piezas? texto = texto + "("+ prod.piezas +") " : texto = texto +''; 
            texto = texto +" ($"+formato.format(prod.precio * prod.cantidad)+")\n"
        });

        texto = texto + "\nModo de Entrega: " + modoEnvio +"\n";

        if(modoEnvio == "Delivery"){
            texto = texto + "Entregar en : " + form.direccion;
            if(form.pisoDepto === ""){
                texto = texto +", "+form.localidad+" entre "+form.entreCalle+": \n\n"
            }else{
                texto = texto +" ("+form.pisoDepto+"), "+form.localidad+" entre "+form.entreCalle+". \n\n"
            }

            texto = texto +"Subtotal: $"+formato.format(monto-descuento)+"*\n*El local indicara el costo del Envío\n\nMuchas Gracias";
        }else{
            texto = texto + "Retirar por: "+ direccion +"\n"
            texto = texto +"Total: $"+formato.format(monto-descuento)+"\n\nMuchas Gracias";
        }
        
        newText("https://wa.me/"+telefono+"?text="+encodeURIComponent(texto));
        // newText("https://wa.me/5491125948493?text="+encodeURIComponent(texto));
        console.log(text)

        // setTimeout(() => {
        //     console.log("Delayed for 1 second.");
        //     enviar();
        //   }, 1000);
    }

    function validarDatos() {
        let ok = false;

        if(modoEnvio =="Delivery"){
            if(form.nombre ==='' || form.direccion ==='' || form.entreCalle ==='' || form.localidad ===''){
                alert("Faltan completar datos, con * es Obligatorio")
            }else{
                ok = true;
            }
        }else{
            if(form.nombre ===''){
                alert("Faltan completar datos, con * es Obligatorio")
            }else{
                ok = true;
            }
        }
        if (ok){
            generarURL();
        }
    }

    useEffect(()=>{
        if(text !== '')
        enviar();
    },[text])

    function enviar(){

        //generarURL()
        cambioNav(1); 
        document.getElementById("modalFin").scrollTo(0,0);
        closeModal();
        emptyCarrito();
        document.getElementById("enviar").click();
    }

    return(
        <div className={isOpen === true ? "modalFinalizar is-open" : "modalFinalizar"} onClick={closeModal} >
            <div id="modalFin" className="modalFinalizar-container" onClick={handleModalContainerClick}>
                {/* <div> */}
                    {/* Header */}
                    <div Style="background:hite;box-shadow: 0 2px 6px 0 rgba(0,0,0,.12); padding-top: 15px;">
                    {/* <img alt="" src="/img/Logo-Sushi-Sake.jpg" width="90" height="90" /> */}
                    <img alt="" src="./img/LogoSushi.png" width="90" height="90" />
                    <div Style="padding-top: 2px; padding-bottom: 5px;"><strong>SUSHI SAKE - {suc.toUpperCase()}</strong></div>

                    </div>
                    {/* Contenido */}
                    {nav===1 && 
                        <div>
                            <div Style="padding-top: 15px;">
                                <div> <strong>Detalle del Pedido</strong> </div>
                                <div>
                                    {
                                        carrito.map( (prod, index) => {
                                            return  <>
                                            <div key={prod.id} Style="display: flex; align-items: center; padding-top: 7px; padding-bottom: 7px;">
                                                {/* <div Style={"flex: 1 0 0%;"} className="flex-grow0"></div> */}
                                                <div Style={"flex: 2 0 0%; text-align: start; padding-left: 50px;"  } className="flex-grow2">{prod.cantidad} x {prod.nombre} {prod.piezas? "("+prod.piezas +")":''} </div>
                                                <div Style={"flex: 1 0 0%; text-align: end;"} className="flex-grow1" >${formato.format(prod.precio * prod.cantidad)}</div>
                                                <div Style={"flex: 1 0 0%;"} className="flex-grow0"></div>
                                            </div>
                                        </>;
                                        })
                                    }

                                    {descuento > 0 &&
                                        <div Style="display: flex; align-items: center; padding-top: 7px; padding-bottom: 7px;">
                                        <div Style={"flex: 1 0 0%;"} className="flex-grow0"></div>
                                        <div Style={"flex: 1 0 0%; text-align: start;" } className="flex-grow2">
                                                <strong>Descuento</strong>
                                            </div>
                                        <div Style={"flex: 1 0 0%; text-align: end;"} className="flex-grow1" >
                                                <strong>${formato.format(-descuento)}</strong> 
                                            </div>
                                        <div Style={"flex: 1 0 0%;"} className="flex-grow0"></div>
                                    </div>
                                    }
                                    <hr Style="margin-top: .5rem; margin-bottom: .5rem; margin-left: 25px; margin-right: 25px;"></hr>
                                    <div Style="display: flex; align-items: center; padding-top: 7px; padding-bottom: 7px;">
                                        <div Style={"flex: 1 0 0%;"} className="flex-grow0"></div>
                                        <div Style={"flex: 1 0 0%; text-align: start;" } className="flex-grow2">
                                            {modoEnvio != "Delivery" &&
                                                <strong>Total</strong>
                                            }
                                            {modoEnvio == "Delivery" &&
                                                <strong>Subtotal</strong>
                                            }
                                            </div>
                                        <div Style={"flex: 1 0 0%; text-align: end;"} className="flex-grow1" >
                                            {modoEnvio != "Delivery" &&
                                                <strong>${formato.format(monto-descuento)}</strong> 
                                            }
                                            {modoEnvio == "Delivery" &&
                                                <strong>${formato.format(monto-descuento)}*</strong>
                                            }
                                            </div>
                                        <div Style={"flex: 1 0 0%;"} className="flex-grow0"></div>
                                    </div>
                                    <hr Style="margin-top: .5rem; margin-bottom: .5rem; margin-left: 25px; margin-right: 25px;"></hr>
                                    <div Style="display: flex; align-items: center; padding-top: 7px; padding-bottom: 7px;">
                                        {/* <div Style={"flex: 1 0 0%;"} className="flex-grow0"></div> */}
                                        <div Style={"flex: 3 0 0%; text-align: end;" } className="flex-grow2">Modo de Entrega: </div>
                                        <div Style={"flex: 1 0 0%; text-align: start;  padding-left: 7px;"} className="flex-grow1" > 
                                            <select value={modoEnvio} onChange={e => setModoEnvio(e.target.value)}>
                                            <option value="Delivery">Delivery</option>
                                            <option value="Take Away">Take Away</option>
                                            </select>
                                        </div>
                                        <div Style={"flex: 1 0 0%;"} className="flex-grow0"></div>
                                    </div>
                                    <hr Style="margin-top: .5rem; margin-bottom: .5rem; margin-left: 25px; margin-right: 25px;"></hr>
                                    <div Style="display: flex; align-items: center; padding-top: 7px; padding-bottom: 7px;">
                                        {/* <div Style={"flex: 1 0 0%;"} className="flex-grow0"></div> */}
                                        <div Style={"flex: 1 0 0%; text-align: end;" } className="flex-grow2">Cod. de Descuento: </div>
                                        <div Style={"flex: 1 0 0%; text-align: start;  padding-left: 7px;"} className="flex-grow1" > 
                                            <input type="text" id="codigoDesc" name="codigoDesc" onBlur={handleBlur} onChange={handleChange} value={form.codigoDesc.toUpperCase()} />
                                        </div>
                                        <div Style={"flex: 1 0 0%;"} className="flex-grow0">
                                            <button className="botonCeleste" Style={"width: auto;height: auto;"} onClick={()=>{console.log(carrito); console.log(form);aplicarDescuento()}}>Aplicar</button>
                                        </div>
                                    </div>
                                    <hr Style="margin-top: .5rem; margin-bottom: .5rem; margin-left: 25px; margin-right: 25px;"></hr>
                                    <div Style="display: flex; align-items: center; padding-top: 7px; padding-bottom: 7px;">
                                        {/* <div Style={"flex: 1 0 0%;"} className="flex-grow0"></div> */}
                                        <div Style={"flex: 4 0 0%; text-align: center;" } className="flex-grow3">

                                            {modoEnvio == "Delivery" && <>
                                                * Costo de Envío se calcula por WhatsApp
                                            </>
                                            }
                                            {modoEnvio != "Delivery" && <>
                                                Retirar por: {direccion}
                                            </>
                                            }
                                            
                                            </div>
                                        {/* <div Style={"flex: 1 0 0%;"} className="flex-grow0"></div> */}
                                    </div>
                                </div>
                            </div>
                            {/* Footer */}
                            <div Style="padding-top: 15px; padding-left: 15px; padding-right: 15px;padding-bottom: 25px;">
                                <button onClick={()=>{cambioNav(2);document.getElementById("modalFin").scrollTo(0,0);}} className="botonCeleste">
                                    Confirmar tu pedido!
                                </button>
                                <button onClick={closeModal} Style="background: #ffdd57; color: black;" className="botonCeleste">
                                    Volver
                                </button>
                            </div>
                            <br/><br/><br/><br/><br/>
                        </div>
                    }
                    {nav===2 && 
                        <div>
                            <div Style="padding-top: 15px;">
                                <div> <strong>Completar Datos</strong> </div>
                            </div>
                            {/* Footer */}
                            <div Style="padding-top: 15px; padding-left: 15px; padding-right: 15px; display: flex;
    flex-direction: column;align-items: center;">

                            <div Style="display: flex; align-items: center; padding-top: 7px; padding-bottom: 7px;">
                                {/* <div Style={"flex: 1 0 0%;"} className="flex-grow0"></div> */}
                                <div Style="width: 100px;">
                                    <label for="nombre">Nombre* </label> 
                                </div>
                                <div Style={"flex: 3 0 0%; text-align: start;"} className="flex-grow1" >
                                    <input type="text" id="nombre" name="nombre" onBlur={handleBlur} onChange={handleChange} value={form.nombre} />
                                </div>
                                {/* <div Style={"flex: 1 0 0%;"} className="flex-grow0"></div> */}
                            </div>

                            {modoEnvio == "Delivery" && <>

                            <div Style="display: flex; align-items: center; padding-top: 7px; padding-bottom: 7px;">
                                {/* <div Style={"flex: 1 0 0%;"} className="flex-grow0"></div> */}
                                <div Style="width: 100px;">
                                    <label for="direccion">Dirección*</label> 
                                </div>
                                <div Style={"flex: 3 0 0%; text-align: start;"} className="flex-grow1" >
                                    <input type="text" id="direccion" name="direccion" onBlur={handleBlur} onChange={handleChange} value={form.direccion}/>
                                </div>
                                {/* <div Style={"flex: 1 0 0%;"} className="flex-grow0"></div> */}
                            </div>

                            <div Style="display: flex; align-items: center; padding-top: 7px; padding-bottom: 7px;">
                                {/* <div Style={"flex: 1 0 0%;"} className="flex-grow0"></div> */}
                                <div Style="width: 100px;">
                                    <label for="pisoDepto">Piso/Dpto </label>
                                </div>
                                <div Style={"flex: 3 0 0%; text-align: start;"} className="flex-grow1" >
                                    <input type="text" id="pisoDepto" name="pisoDepto" onBlur={handleBlur} onChange={handleChange} value={form.pisoDepto} />
                                </div>
                                {/* <div Style={"flex: 1 0 0%;"} className="flex-grow0"></div> */}
                            </div>

                            <div Style="display: flex; align-items: center; padding-top: 7px; padding-bottom: 7px;">
                                {/* <div Style={"flex: 1 0 0%;"} className="flex-grow0"></div> */}
                                <div Style="width: 100px;">
                                    <label for="entreCalle">Entre calles* </label>
                                </div>
                                <div Style={"flex: 3 0 0%; text-align: start;"} className="flex-grow1" >
                                    <input type="text" id="entreCalle" name="entreCalle" onBlur={handleBlur} onChange={handleChange} value={form.entreCalle} />
                                </div>
                                {/* <div Style={"flex: 1 0 0%;"} className="flex-grow0"></div> */}
                            </div>

                            <div Style="display: flex; align-items: center; padding-top: 7px; padding-bottom: 7px;">
                                {/* <div Style={"flex: 1 0 0%;"} className="flex-grow0"></div> */}
                                <div Style="width: 100px;">
                                    <label for="localidad">Localidad* </label>
                                </div>
                                <div Style={"flex: 3 0 0%; text-align: start;"} className="flex-grow1" >
                                    <input type="text" id="localidad" name="localidad" onBlur={handleBlur} onChange={handleChange} value={form.localidad} />
                                </div>
                                {/* <div Style={"flex: 1 0 0%;"} className="flex-grow0"></div> */}
                            </div>
                            </>
                            }
                                <div Style="padding-top: 15px; padding-left: 15px; padding-right: 15px;padding-bottom: 25px;">
                                    <button onClick={()=>{validarDatos()}} className="botonCeleste">
                                        Enviar Pedido x WhatsApp!
                                    </button>
                                    <button onClick={()=>{cambioNav(1);document.getElementById("modalFin").scrollTo(0,0);}} Style="background: #ffdd57; color: black;" className="botonCeleste">
                                        Volver a Detalle
                                    </button>
                                </div>
                                <br/><br/><br/><br/><br/>
                                <br/><br/><br/><br/>
                                <a id="enviar" href={text} Style="display:none;" target="_blank" rel="noreferrer">x</a>
                            </div>
                        </div>
                    }
                    {/* {children} */}
                {/* </div> */}
            </div>
        </div>
    );
};

export default Modal;